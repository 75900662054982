import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from 'react-router-dom'
import { Layout } from '@components/templates/Layout/Component'
import { Home } from '@pages/App/Component'
import { Gbd } from '@pages/Gbd/Component'
import { Adm } from '@pages/Adm/Component'
import { Dtg } from '@pages/Dtg/Component'
import { IntroductionPage } from '@root/pages/IntroductionPage/Component'
import { Vda } from './pages/Vda/Component'

// The website consists of multiple possible routes. Each routes is wrapped in a Layout
// At present it will be 4 different pages

// 1. The introduction page where the keyboard is presented. This should only be visible when on location
// 2. The GBD test. This has a required parameter 'variant' which can be 'home' or 'on-location'
//    It also has an optional parameter 'pages' which indicate the amount of pages it should have. (specifically on the home variant)
// 3. The ADM test. This has a required parameter 'variant' which can be 'home' or 'on-location'
// 4. The DTG test. This has a required parameter 'variant' which can be 'home' or 'on-location'
//    It also has a few optional parameters. These are 'stimuli', 'durations' and 'seconds'. These all share the property that it has to be a ;-seperated string.
//    Stimuli and Durations should be the same length (when counting indexes), Seconds can be different. All should be numerical.

const router = createBrowserRouter([

  {
    path: '/',
    element: <Layout type='home'><Home /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/gbd/:variant/:pages',
    element: <Layout type='gbd'><Gbd /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/gbd/:variant',
    element: <Layout type='gbd'><Gbd /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/adm/:variant/:numbers',
    element: <Layout type='adm'><Adm /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    // /dta/home/100;100;100/1100;1000;800/150
    path: '/dtg/:variant/:stimuli/:durations/:seconds',
    element: <Layout type='dtg'><Dtg /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/dtg/:variant',
    element: <Layout type='dtg'><Dtg /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/vda/:variant',
    element: <Layout type='vda'><Vda /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />
  },
  {
    path: '/introduction',
    element: <Layout type='introduction'> <IntroductionPage /></Layout>,
    loader: () => (<p>Loading..</p>),
    errorElement: <ErrorBoundary />

  }
])

function Router (): JSX.Element {
  return (
    <RouterProvider router={router} />
  )
}

function ErrorBoundary (): JSX.Element {
  const error = useRouteError()
  console.error(error)
  // Uncaught ReferenceError: path is not defined
  return <div>Er is iets misgegaan!</div>
}

export default Router
