import EndScreen from '@root/components/molecules/EndScreen/Component'
import Introduction from '@root/components/molecules/Introduction/Component'
import { AdmView } from '@root/components/organisms/AdmView/Component'
import { AdmViewPractise } from '@root/components/organisms/AdmViewPractise/Component'

import { useAppContext } from '@root/global/context'
import { ProgressStateAdm } from '@root/misc/constants'
import { checkTitle } from '@root/misc/helpers'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'

// For each different step in a test, this file renders the HTML for that specific step.
export function Adm (): JSX.Element {
  const { progressStateAdm, setProgressStateAdm, admData } = useAppContext()
  const location = useLocation()
  const { variant, pages, numbers } = useParams()
  const numbersToFind = Number(numbers) + 9
  if (numbersToFind !== 39 && numbersToFind !== 59) {
    throw new Error('Invalid numbers to find given')
  }

  if (progressStateAdm === ProgressStateAdm.Introduction) {
    return (
      <>
        <Introduction
          nextFn={() => setProgressStateAdm(ProgressStateAdm.InstructionPart1)}
          title={checkTitle(location.pathname)}
          label={t({ id: 'adm.introduction.title', message: 'Lees de instructie' })}
          description={(
            <>
              <p>
                <Trans id='adm.introduction'>
                  Bij deze test gaat het om selectieve aandacht; het vermogen om zich te kunnen richten op relevante
                  signalen, waarbij niet relevante signalen moeten worden genegeerd. <br /> <br />
                  Lees de instructie goed door en start de test pas als u deze volledig hebt begrepen.
                </Trans>
              </p>
            </>
          )}
        />
      </>
    )
  }

  if (progressStateAdm === ProgressStateAdm.InstructionPart1) {
    return (
      <>
        <Introduction
          nextFn={() => setProgressStateAdm(ProgressStateAdm.PractisePart1)}
          title={checkTitle(location.pathname)}
          label={t({ id: 'adm.instruction.title.page1', message: 'Ga naar de oefentoets' })}
          description={(
            <>
              <p>
                <Trans id='adm.instruction'>
                  Je ziet straks de getallen 10 tot en met {numbersToFind}. De getallen staan in willekeurige volgorde en hebben vijf
                  verschillende kleuren.<br />
                  Het is de bedoeling dat je deze getallen in de juiste volgorde opzoekt en er niet een overslaat. Als je
                  een getal gevonden heeft, druk je kort op de toets met dezelfde kleur als het getal.<br /> <br />

                  Onderaan het scherm staat straks een balkje dat bij elke goede reactie een stukje langer wordt. Aan
                  het balkje kun je zien hoe ver je met de test gevorderd bent. Op het moment dat het balkje helemaal
                  vol is, ben je aan het einde van de test gekomen.<br /> <br />

                  Het gaat er om dat je de test snel en nauwkeurig maakt.
                </Trans>
              </p>
            </>
          )}
        />
      </>
    )
  }
  if (progressStateAdm === ProgressStateAdm.InstructionPart2) {
    return (
      <>
        <Introduction
          nextFn={() => setProgressStateAdm(ProgressStateAdm.PractisePart2)}
          title={checkTitle(location.pathname)}
          label={t({ id: 'adm.instruction.title.page2', message: 'Ga naar de oefentoets' })}
          description={(
            <>
              <p>
                <Trans id='adm.instruction.page2'>
                  Einde van het eerste deel van de test. Er volgt nu een tweede deel.<br /> <br />

                  Ook nu moet je getallen zo snel mogelijk opzoeken en op de gekleurde toetsen drukken. Het gaat
                  weer om de getallen 10 tot en met {numbersToFind}, die opgezocht moeten worden.
                  Bij dit onderdeel staat er onder elk getal nog een klein getal. Zo dadelijk moet je de GROTE getallen
                  opzoeken, maar je moet op de toets met dezelfde kleur als het KLEINE getal drukken.
                </Trans>
              </p>
            </>
          )}
        />
      </>
    )
  }

  if (progressStateAdm === ProgressStateAdm.PractisePart1) {
    return (
      <AdmViewPractise key='practise1' part={1} pages={parseInt(pages ?? '1', 10) ?? 1} variant={variant ?? 'on-location'} endFn={() => setProgressStateAdm(ProgressStateAdm.TestPart1)} />
    )
  }

  if (progressStateAdm === ProgressStateAdm.TestPart1) {
    return (
      <AdmView key='test1' part={1} pages={parseInt(pages ?? '1', 10) ?? 1} variant={variant ?? 'on-location'} numbersToFind={numbersToFind} endFn={() => setProgressStateAdm(ProgressStateAdm.InstructionPart2)} />
    )
  }

  if (progressStateAdm === ProgressStateAdm.PractisePart2) {
    return (
      <AdmViewPractise key='practise2' part={2} pages={parseInt(pages ?? '1', 10) ?? 1} variant={variant ?? 'on-location'} endFn={() => setProgressStateAdm(ProgressStateAdm.TestPart2)} />
    )
  }

  if (progressStateAdm === ProgressStateAdm.End) {
    return (
      <EndScreen type='adm' data={admData} variant={variant ?? ''} />
    )
  }
  return (
    <AdmView key='test2' part={2} pages={parseInt(pages ?? '1', 10) ?? 1} variant={variant ?? 'on-location'} numbersToFind={numbersToFind} endFn={() => setProgressStateAdm(ProgressStateAdm.End)} />
  )
}
